// noinspection JSUnresolvedVariable

import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import tmdbApi from '../../api/tmdbApi';
import apiConfig from '../../api/apiConfig';

import './detail.scss';
import CastList from './CastList';
import VideoList from './VideoList';

import MovieList from '../../components/movie-list/MovieList';
import WatchMovie from "./WatchMovie";
import WatchTV from "./WatchTV";

import {Helmet, HelmetProvider} from "react-helmet-async";

import SDMovie from "../../components/structured-data/SDMovie";
import SDTV from "../../components/structured-data/SDTV.js";

const Detail = () => {

    const {category, id, slug, se, ep} = useParams();

    //console.log(id);

    const [item, setItem] = useState(null);

    useEffect(() => {
        const getDetail = async () => {
            const response = await tmdbApi.detail(category, id, {params: {}});
            setItem(response);
            window.scrollTo(0, 0);
        }

        getDetail().then();
    }, [category, id, slug, se, ep]);

    const [visible, setVisible] = React.useState(false);

    const ctype = category.toUpperCase();

    return (
        <>
            {
                item && (
                    <HelmetProvider>


                        {se ? (
                            <SDTV id={item.id}
                                  name={item.title || item.name}
                                  poster={apiConfig.originalImage(item.backdrop_path || item.poster_path)}
                                  release_date={item.release_date}
                                  vote_average={item.vote_average + 10}
                                  vote_count={item.vote_count + 1}
                                  se={se}
                                  ep={ep}
                            />
                        ) : (
                            <SDMovie id={item.id}
                                     type={category === 'movie' ? ("movie") : ("TVSeries")}
                                     name={item.title || item.name}
                                     poster={apiConfig.originalImage(item.backdrop_path || item.poster_path)}
                                     release_date={item.release_date}
                                     vote_average={item.vote_average + 10}
                                     vote_count={item.vote_count + 1}
                            />
                        )}


                        <Helmet>
                            <meta name='robots'
                                  content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'/>
                            <title>Watch {se ? (`${item.title || item.name} Season-${se} Episode-${ep}`) : (`${item.title || item.name}`)} Online
                                Free</title>
                            <meta name="description" content={item.overview}/>
                            <link rel="canonical" href={window.location.href}/>
                            <meta property="og:locale" content="en_US"/>
                            <meta property="og:type" content={ctype}/>
                            <meta property="og:title"
                                  content={se ? (`${item.title || item.name} Season-${se} Episode-${ep}`) : (`${item.title || item.name}`)}/>
                            <meta property="og:description" content={item.overview}/>
                            <meta property="og:url" content={window.location.href}/>
                            <meta property="og:site_name" content={process.env.REACT_APP_WEBSITE_NAME}/>
                            <meta property="og:image"
                                  content={apiConfig.originalImage(item.backdrop_path || item.poster_path)}/>
                            <meta property="og:image:width" content="2000"/>
                            <meta property="og:image:height" content="3000"/>
                            <meta property="og:image:type" content="image/jpg"/>
                            <script type="text/javascript" charSet="UTF-8"
                                    src="//cdn.cookie-script.com/s/75f6cf9266749b3d8a98b4bd1f9c4697.js"></script>
                        </Helmet>
                        <div className="banner"
                             style={{backgroundImage: `url(${apiConfig.originalImage(item.backdrop_path || item.poster_path)})`}}></div>
                        <div className="mb-3 movie-content container">
                            <div className="movie-content__poster">
                                <div className="movie-content__poster__img"
                                     style={{backgroundImage: `url(${apiConfig.originalImage(item.poster_path || item.backdrop_path)})`}}></div>
                            </div>
                            <div className="movie-content__info">
                                <h1 className="title">{item.title || item.name}</h1>
                                <h2 className="title"> {se > '0' ? (`Season ${se} Episode ${ep}`) : (``)}</h2>

                                <div className="genres">
                                    {
                                        item.genres && item.genres.slice(0, 5).map((genre, i) => (
                                            <span key={i} className="genres__item">{genre.name}</span>
                                        ))
                                    }

                                    <button className="btn"
                                            onClick={() => setVisible(!visible)}>{visible ? 'Hide' : 'Watch'} Trailer
                                    </button>
                                </div>
                                <div className="section mb-3">
                                    {visible && <VideoList id={item.id}/>}
                                </div>

                                <p className="overview">{item.overview}</p>

                                <div className="cast">
                                    <div className="section__header">
                                        <h2>Casts</h2>
                                    </div>
                                    <CastList id={item.id}/>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="section mb-3">
                                {category === 'movie' ? (
                                    <WatchMovie category={category} id={item.imdb_id} name={item.title || item.name}
                                                year={new Date(`${item.release_date}`).getFullYear()}
                                                img={apiConfig.originalImage(item.backdrop_path)}/>
                                ) : (
                                    <WatchTV se={se} ep={ep} category={category} id={item.id} name={item.title || item.name}
                                             year={item.release_date} seasons={item.number_of_seasons}
                                             img={apiConfig.originalImage(item.backdrop_path)}/>
                                )}
                            </div>
                        </div>

                        <div className="container">
                            <div className="section mb-3">
                                <div className="section__header mb-2">
                                    <h2>Similar</h2>
                                </div>
                                <MovieList category={category} type="similar" id={item.id}/>
                            </div>
                        </div>
                    </HelmetProvider>
                )
            }
        </>
    );
}

export default Detail;
