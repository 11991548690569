// noinspection JSUnresolvedVariable

import React, {useEffect, useState} from "react";
import Dropdown from 'react-bootstrap/Dropdown';
//import {category} from "../../api/tmdbApi";
import slugify from 'slug';

const EpisodesList = (props) => {

    const [episodes, setAdvice] = useState(null);

    useEffect(() => {
        const url = `https://api.themoviedb.org/3/tv/${props.id}/season/${props.season}?api_key=64aae259b81dadaafc346256846a0490&language=en-US`;

        const fetchData = async () => {
            try {
                const response = await fetch(url);
                const json = await response.json();
                setAdvice(json.episodes);

            } catch (error) {
                //console.log("error", error);
            }
        };
        fetchData().then();
    });


    const epc = episodes?.length;
    const seas = [];
    const link = props.id + '-' + slugify(`${props.title}`);
    for (let i = 1; i <= epc; i++) {
        seas.push(<Dropdown.Item key={`ep${i}`} eventKey={props.season}
                                 href={`/tv/${link}/season/${props.season}/episode/${i}/`}>Eepisode {i}</Dropdown.Item>);
    }

    return (<>{seas}</>);

}
export default EpisodesList;