// noinspection JSUnresolvedVariable

import React, {useEffect, useState} from 'react';

import {useParams} from 'react-router-dom';

import tmdbApi from '../../api/tmdbApi';
//import apiConfig from "../../api/apiConfig.js";

const SDMovie = (props) => {
    const {category} = useParams();

    const [casts, setCasts] = useState([]);

    useEffect(() => {
        const getCredits = async () => {

            const res = await tmdbApi.credits(category, props.id);

            setCasts(res.cast);

        }
        getCredits().then();
    }, [category, props.id]);

    const StructuredData = {
        "@context": "https://schema.org",
        "@graph": [{
            "@type": `${props.type}`,
            "@context": "https://schema.org",
            "name": `${props.name}`,
            "image": `${props.poster}`,
            "url": `${window.location.href}`,
            "datePublished": `${props.release_date}`,
            "actor": casts.map((item) => (

                {
                    "@type": "Person",
                    "name": `${item.name}`
                }

            )),
            "director": {
                "@type": "Person",
                "name": "Not Available"
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingCount": `${props.vote_count}`,
                "ratingValue": `${props.vote_average / 2}/5`
            },
            "review": {
                "@type": "Review",
                "author": {
                    "@type": "Person",
                    "name": "No Name"
                },
                "reviewBody": "Empty"
            },
            "publisher": {
                "@type": "Organization",
                "name": `${process.env.REACT_APP_WEBSITE_NAME}`,
                "logo": {
                    "@type": "ImageObject",
                    "url": "https://www.moviestowatch.vip/apple-touch-icon.png"
                }
            }
        }]
    };

    return (
        <>
            <script type="application/ld+json">
                {JSON.stringify(StructuredData)}
            </script>
        </>
    );

}

export default SDMovie;