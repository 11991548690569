import React from 'react';
import {Link} from 'react-router-dom';

import {OutlineButton} from '../components/button/Button';
import HeroSlide from '../components/hero-slide/HeroSlide';
import MovieList from '../components/movie-list/MovieList';

import {category, movieType, tvType} from '../api/tmdbApi';
import {Helmet, HelmetProvider} from "react-helmet-async";

const Home = () => {

    const StructuredData = {
        "@context": "https://schema.org",
        "@type": "WebSite",
        name: `${process.env.REACT_APP_WEBSITE_NAME}`,
        url: `${process.env.REACT_APP_URL}`,
        logo: {
            "@type": "ImageObject",
            "url": `${process.env.REACT_APP_URL}/static/media/logo.dbf54973.png`
        },
        potentialAction: {
            "@type": "SearchAction",
            target: `${process.env.REACT_APP_URL}/movie/search/{search_term_string}`,
            "query-input": "required name=search_term_string"
        },
    };

    return (
        <HelmetProvider>
            <Helmet>
                <meta name='robots'
                      content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'/>
                <title>{process.env.REACT_APP_WEBSITE_TITLE}{process.env.REACT_APP_WEBSITE_NAME}</title>
                <meta name="description" content={process.env.REACT_APP_DESCRIPTION}/>
                <link rel="canonical" href={window.location.href}/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:type" content="Website"/>
                <meta property="og:title"
                      content={process.env.REACT_APP_WEBSITE_TITLE + process.env.REACT_APP_WEBSITE_NAME}/>
                <meta property="og:description" content={process.env.REACT_APP_DESCRIPTION}/>
                <meta property="og:url" content={window.location.href}/>
                <meta property="og:site_name" content={process.env.REACT_APP_WEBSITE_NAME}/>
                <meta property="og:image" content={process.env.REACT_APP_IMG}/>
                <meta property="og:image:width" content="512"/>
                <meta property="og:image:height" content="512"/>
                <meta property="og:image:type" content="image/png"/>
                <script type="text/javascript" charSet="UTF-8"
                        src="//cdn.cookie-script.com/s/75f6cf9266749b3d8a98b4bd1f9c4697.js"></script>
                <script type="application/ld+json">
                    {JSON.stringify(StructuredData)}
                </script>

            </Helmet>
            <HeroSlide/>
            <div className="container">
                <div className="section mb-3">
                    <h1 className="page-header">Watch Movies & Tv Series</h1>
                    <div className="section__header mb-2">
                        <h2>Trending Movies</h2>
                        <Link to="/movie">
                            <OutlineButton className="small">View more</OutlineButton>
                        </Link>
                    </div>
                    <MovieList category={category.movie} type={movieType.popular}/>
                </div>

                <div className="section mb-3">
                    <div className="section__header mb-2">
                        <h2>Top Rated Movies</h2>
                        <Link to="/movie">
                            <OutlineButton className="small">View more</OutlineButton>
                        </Link>
                    </div>
                    <MovieList category={category.movie} type={movieType.top_rated}/>
                </div>

                <div className="section mb-3">
                    <div className="section__header mb-2">
                        <h2>Trending TV</h2>
                        <Link to="/tv">
                            <OutlineButton className="small">View more</OutlineButton>
                        </Link>
                    </div>
                    <MovieList category={category.tv} type={tvType.popular}/>
                </div>

                <div className="section mb-3">
                    <div className="section__header mb-2">
                        <h2>Top Rated TV</h2>
                        <Link to="/tv">
                            <OutlineButton className="small">View more</OutlineButton>
                        </Link>
                    </div>
                    <MovieList category={category.tv} type={tvType.top_rated}/>
                </div>
            </div>
        </HelmetProvider>
    );
}

export default Home;
