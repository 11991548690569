import React from 'react';
import EpisodesList from "./EpisodesList";
import "../../bootstrap.css";

import {ButtonGroup, Dropdown, DropdownButton} from 'react-bootstrap';


const SeasonsList = props => {

    const seas = [];
    for (let i = 1; i <= props.seasons; i++) {
        seas.push(<DropdownButton
            as={ButtonGroup}
            key={`se${i}`}
            id={`dropdown-variants-${i}`}
            variant={`seasons-${i}`}
            title={`Seasons ${i}`}
            className="btnnn"
        > <EpisodesList id={props.id} title={props.title} season={i} key={`se${i}`}/> </DropdownButton>);
    }

    return (<Dropdown>{seas}</Dropdown>);

}
export default SeasonsList;