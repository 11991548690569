// noinspection JSUnresolvedVariable

import React, {useEffect, useState} from 'react';

import {useParams} from 'react-router-dom';

import tmdbApi from '../../api/tmdbApi';

const SDTV = (props) => {
    const {category} = useParams();

    const [casts, setCasts] = useState([]);

    useEffect(() => {
        const getCredits = async () => {

            const res = await tmdbApi.credits(category, props.id);

            setCasts(res.cast);

        }
        getCredits().then();
    }, [category, props.id]);

    const StructuredData = {
        "@context": "https://schema.org",
        "@type": "TVEpisode",
        "partOfTVSeries": {
            "@type": "TVSeries",
            "name": `${props.name}`
        },
        "name": `Season ${props.se} Episode ${props.ep}`,
        "partOfSeason": {
            "@type": "TVSeason",
            "seasonNumber": `${props.se}`
        },
        "episodeNumber": `${props.ep}`,
        "image": `${props.poster}`,
        "url": `${window.location.href}`,
        "director": {
            "@type": "Person",
            "name": "Not Available"
        },
        "actor": casts.map((item) => (

            {
                "@type": "Person",
                "name": `${item.name}`
            }

        )),
        "datePublished": `${props.release_date}`,
        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingCount": `${props.vote_count}`,
            "ratingValue": `${props.vote_average / 2}/5`
        },
        "review": {
            "@type": "Review",
            "author": {
                "@type": "Person",
                "name": "No Name"
            },
            "reviewBody": "Empty"
        },
        "publisher": {
            "@type": "Organization",
            "name": `${process.env.REACT_APP_WEBSITE_NAME}`,
            "logo": {
                "@type": "ImageObject",
                "url": "https://www.moviestowatch.vip/apple-touch-icon.png"
            }
        }
    };

    return (
        <>
            <script type="application/ld+json">
                {JSON.stringify(StructuredData)}
            </script>
        </>
    );

}

export default SDTV;