import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';

import './movie-grid.scss';

import MovieCard from '../movie-card/MovieCard';
import Button, {OutlineButton} from '../button/Button';
import Input from '../input/Input'

import tmdbApi, {category, movieType, tvType} from '../../api/tmdbApi';
import {Helmet, HelmetProvider} from "react-helmet-async";

const MovieGrid = props => {

    const [items, setItems] = useState([]);

    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);

    const {keyword} = useParams();

    useEffect(() => {
        const getList = async () => {
            let response;

            if (keyword === undefined) {
                const params = {};
                switch (props.category) {
                    case category.movie:
                        response = await tmdbApi.getMoviesList(movieType.upcoming, {params});
                        break;
                    default:
                        response = await tmdbApi.getTvList(tvType.popular, {params});
                }
            } else {
                const params = {
                    query: keyword
                }
                response = await tmdbApi.search(props.category, {params});
            }
            setItems(response.results);
            setTotalPage(response.total_pages);
        }
        getList().then();
    }, [props.category, keyword]);

    const loadMore = async () => {
        let response;
        if (keyword === undefined) {
            const params = {
                page: page + 1
            };
            switch (props.category) {
                case category.movie:
                    response = await tmdbApi.getMoviesList(movieType.upcoming, {params});
                    break;
                default:
                    response = await tmdbApi.getTvList(tvType.popular, {params});
            }
        } else {
            const params = {
                page: page + 1,
                query: keyword
            }
            response = await tmdbApi.search(props.category, {params});
        }
        setItems([...items, ...response.results]);
        setPage(page + 1);
    }

    return (
        <>

            <div className="section mb-3">
                <MovieSearch category={props.category} keyword={keyword}/>
            </div>
            <div className="movie-grid">
                {
                    items.map((item, i) => <MovieCard category={props.category} item={item} key={i}/>)
                }
            </div>
            {
                page < totalPage ? (
                    <div className="movie-grid__loadmore">
                        <OutlineButton className="small" onClick={loadMore}>Load more</OutlineButton>
                    </div>
                ) : null
            }
        </>
    );
}

const MovieSearch = props => {

    const history = useHistory();

    const [keyword, setKeyword] = useState(props.keyword ? props.keyword : '');

    const goToSearch = useCallback(
        () => {
            if (keyword.trim().length > 0) {
                history.push(`/${category[props.category]}/search/${keyword}`);
            }
        },
        [keyword, props.category, history]
    );

    useEffect(() => {
        const enterEvent = (e) => {
            e.preventDefault();
            if (e.keyCode === 13) {
                goToSearch();
            }
        }
        document.addEventListener('keyup', enterEvent);
        return () => {
            document.removeEventListener('keyup', enterEvent);
        };
    }, [keyword, goToSearch]);

    let Ttype;

    if (props.keyword) {
        Ttype = process.env.REACT_APP_SEARCHING + "" + props.keyword.charAt(0).toUpperCase() + props.keyword.slice(1);
    } else if (props.category === "movie") {
        Ttype = process.env.REACT_APP_TITLE_MOVIE;
    } else {
        Ttype = process.env.REACT_APP_TITLE_TV;
    }

    const str = props.category;
    const ctype = str.charAt(0).toUpperCase() + str.slice(1);

    const StructuredData = {
        "@context": "https://schema.org",
        "@type": "WebSite",
        name: `${process.env.REACT_APP_WEBSITE_NAME}`,
        url: `${process.env.REACT_APP_URL}`,
        logo: {
            "@type": "ImageObject",
            "url": `${process.env.REACT_APP_URL}/static/media/logo.dbf54973.png`
        },
        potentialAction: {
            "@type": "SearchAction",
            target: `${process.env.REACT_APP_URL}/${props.category}/search/{search_term_string}`,
            "query-input": "required name=search_term_string"
        },
    };


    return (
        <HelmetProvider>
            <Helmet>
                <meta name='robots'
                      content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'/>
                <title>{Ttype} {process.env.REACT_APP_WEBSITE_NAME}</title>
                <meta name="description" content={process.env.REACT_APP_DESCRIPTION}/>
                <link rel="canonical" href={window.location.href}/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:type" content={ctype}/>
                <meta property="og:title" content={Ttype + process.env.REACT_APP_WEBSITE_NAME}/>
                <meta property="og:description" content={process.env.REACT_APP_DESCRIPTION}/>
                <meta property="og:url" content={window.location.href}/>
                <meta property="og:site_name" content={process.env.REACT_APP_WEBSITE_NAME}/>
                <meta property="og:image" content={process.env.REACT_APP_IMG}/>
                <meta property="og:image:width" content="512"/>
                <meta property="og:image:height" content="512"/>
                <meta property="og:image:type" content="image/png"/>

                <script type="application/ld+json">
                    {JSON.stringify(StructuredData)}
                </script>

            </Helmet>
            <div className="movie-search">
                <Input
                    type="text"
                    placeholder="Enter keyword"
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                />
                <Button className="small" onClick={goToSearch}>Search</Button>
            </div>
        </HelmetProvider>
    )
}

export default MovieGrid;
