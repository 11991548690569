import React from 'react';


const Footertext = () => {
    return (
        <span><p>Watching movies and many free TV shows with video quality up to 1080p on Moviestowatch! You can watch here for free anytime on many popular electronic devices. It has a huge movie library that includes hundreds of thousands of diverse content from many countries worldwide. You will access new content daily without fear of running out of movies to watch. Please join moviestowatch.vip to enjoy moments of entertainment with friends and relatives.</p>
    <p>Moviestowatch is a familiar destination for many movie enthusiasts today. This website owns a huge number of movies from many countries to ensure it meets the interests of many people. You can immerse yourself in the mysterious and interesting world of cinema with just a few mouse clicks.</p>
    <p>When you visit moviestowatch, you will not only be surprised by the huge movie database but also for free, you will not need to care about your tight financial situation. Here you can watch movies on many popular electronic devices as long as there is internet.</p>
    <p>Moviestowatch has a user-friendly interface, it is maximally streamlined so that viewers can search and choose their favorite movie quickly and easily.</p> </span>
    );
}

export default Footertext;
