import React, {useEffect, useRef} from 'react';

//import { Player } from 'video-react';
//import './video-react.css';
import Tabs from "../../components/player/Tabs.js";
import '../../components/player/Tabs.css';

const WatchMovie = props => {

    const iframeRef = useRef(null);

    useEffect(() => {
        const height = iframeRef.current.offsetWidth * 9 / 16 + 'px';
        iframeRef.current.setAttribute('height', height);


    }, []);

    function play() {
        const btn = document.getElementById('onc-hid');
        const x = document.getElementById("play");

        if (x.style.display === "none") {
            x.style.display = "block";
            btn.style.visibility = 'hidden';
        } else {
            x.style.display = "none";
        }
    }

    return (
        <div className="video">
            <Tabs>
                <div label="Video-1">
                    <iframe
                        src={`https://v2.vidsrc.me/embed/${props.id}/`}
                        ref={iframeRef}
                        width="100%"
                        title={props.name}
                        className="iframe myhid"
                        id="play"
                        allowFullScreen></iframe>
                    <div className="iframe" id="onc-hid">
                        <img alt="Poster" className="v-video-p"
                             src={props.img}/>
                        <button className="btn v-play-p" onClick={play}><i className="bx bx-play"></i></button>
                    </div>
                </div>

                <div label="Video-2">
                    <iframe
                        src={`https://vidcloud.click/player.php?imdb=${props.id}`}
                        ref={iframeRef}
                        width="100%"
                        title={props.name}
                        className="iframe"
                        allowFullScreen></iframe>
                </div>
            </Tabs>
        </div>
    )
}

export default WatchMovie;
