// noinspection JSUnresolvedVariable

import React, {useEffect, useRef, useState} from 'react';
import SeasonsList from "./SeasonsList";
import Tabs from "../../components/player/Tabs.js";
import '../../components/player/Tabs.css';

const WatchTV = (props) => {

    const [imdb, setAdvice] = useState(null);

    useEffect(() => {
        const url = `https://api.themoviedb.org/3/tv/${props.id}?api_key=64aae259b81dadaafc346256846a0490&append_to_response=external_ids`;

        const fetchData = async () => {
            try {
                const response = await fetch(url);
                const json = await response.json();
                const imdb = json.external_ids.imdb_id;
                setAdvice(imdb);

            } catch (error) {
                console.log("error", error);
            }
        };
        fetchData().then();
    });


    const iframeRef = useRef(null);

    useEffect(() => {
        const height = iframeRef.current.offsetWidth * 9 / 16 + 'px';
        iframeRef.current.setAttribute('height', height);
    }, []);

    function play() {
        const btn = document.getElementById('onc-hid');
        const x = document.getElementById("play");

        if (x.style.display === "none") {
            x.style.display = "block";
            btn.style.visibility = 'hidden';
        } else {
            x.style.display = "none";
        }
    }

    return (
        <>
            <div className="video">
                <div className="video__title">
                    <h1 className="page-header">Watch TV Series
                        - {props.name} {props.se ? (`Se-${props.se} Ep-${props.ep}`) : (``)}</h1>
                </div>

                <Tabs>
                    <div label="Video-1">
                        <iframe
                            src={props.se ? (`https://v2.vidsrc.me/embed/${imdb}/${props.se}-${props.ep}/`) : (`https://v2.vidsrc.me/embed/${imdb}/1-1/`)}
                            ref={iframeRef}
                            width="100%"
                            title={props.name}
                            className="iframe myhid"
                            id="play"
                            allowFullScreen></iframe>
                        <div className="iframe" id="onc-hid">
                            <img alt="Poster" className="v-video-p"
                                 src={props.img}/>
                            <button className="btn v-play-p" onClick={play}><i className="bx bx-play"></i></button>
                        </div>
                    </div>
                    <div label="Video-2">
                        <iframe
                            src={props.se ? (`https://trailers.to/player/embed/imdb/${imdb}/s${props.se}e${props.ep}`) : (`https://trailers.to/player/embed/imdb/${imdb}/s1e1`)}
                            ref={iframeRef}
                            width="100%"
                            title={props.name}
                            className="iframe"
                            allowFullScreen></iframe>
                    </div>
                </Tabs>
            </div>
            <SeasonsList id={props.id} title={props.name} seasons={props.seasons}/>
        </>
    )
}

export default WatchTV;