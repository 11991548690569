// noinspection JSUnresolvedVariable

import React, {useEffect, useRef, useState} from 'react';

import {useParams} from 'react-router-dom';

import tmdbApi from '../../api/tmdbApi';

const VideoList = props => {

    const {category} = useParams();

    const [videos, setVideos] = useState([]);

    useEffect(() => {
        let unmounted = false;
        const getVideos = async () => {
            const res = await tmdbApi.getVideos(category, props.id);
            if (!unmounted) {
                setVideos(res.results.slice(0, 1));
            }
        }

        getVideos().then(props);
        return () => {
            unmounted = true
        };
    }, [category, props.id, props]);

    return (
        <>
            {
                videos.map((item, i) => (
                    <Video key={i} item={item}/>
                ))
            }
        </>
    );
}

const Video = props => {

    const item = props.item;

    const iframeRef = useRef(null);

    useEffect(() => {
        const height = iframeRef.current.offsetWidth * 9 / 16 + 'px';
        iframeRef.current.setAttribute('height', height);
    }, []);

    return (
        <div className="video">
            <iframe
                src={`https://www.youtube.com/embed/${item.key}`}
                ref={iframeRef}
                width="95%"
                title={item.name}
                allowFullScreen></iframe>
        </div>
    )
}

export default VideoList;
