// noinspection JSUnresolvedVariable

import React from 'react';

import './movie-card.scss';

import {Link} from 'react-router-dom';
import slugify from 'slug';

import Button from '../button/Button';

import {category} from '../../api/tmdbApi';
import apiConfig from '../../api/apiConfig';

const MovieCard = props => {

    const item = props.item;

    const link = '/' + category[props.category] + '/' + item.id + '-' + slugify(`${item.title || item.name}`);
    const urp = 'https://api.moviestowatch.vip/index.php?indexer=' + category[props.category] + '-' + item.id + '-' + slugify(`${item.title || item.name}`);
    const bg = apiConfig.w500Image(item.poster_path || item.backdrop_path);

    function submitter() {
        fetch(`${urp}`, {mode: 'cors'})
            .then((response) => console.log(response));
    }

    const releaseYear = item.release_date ? String(item.release_date).slice(0, 4) : '';

    return (
        <Link to={link} onClick={submitter}>
            <div className="movie-card" style={{backgroundImage: `url(${bg})`}}>
                <Button>
                    <i className="bx bx-play"></i>
                </Button>
            </div>
            <h3>{item.title || item.name} {releaseYear} </h3>
        </Link>
    );
}

export default MovieCard;
