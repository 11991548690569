import React from 'react';

import 'swiper/swiper.min.css';
import './assets/boxicons-2.0.7/css/boxicons.min.css';
import './App.scss';

import {BrowserRouter, Route} from 'react-router-dom';

import Header from './components/header/Header.js';
import Footer from './components/footer/Footer.js';

import Routes from './config/Routes.js';
import ReactGA from "react-ga4";

function App() {

    ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_AN}`);

    ReactGA.send({hitType: "pageview", page: `${window.location.href}`});
    return (
        <BrowserRouter>
            <Route render={props => (
                <>
                    <Header {...props}/>
                    <Routes/>
                    <Footer/>
                </>
            )}/>
        </BrowserRouter>
    );
}

export default App;
